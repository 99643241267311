<template>
  <div>
    <v-container>
    <navbar title="دسته بندی ها"/>
    <loading-page v-if="loading"/>
    <v-main class="pt-15">
    <v-row class="pa-1">
      <v-col v-for="categorie in categories" :key="categorie.id" cols="6" class="">
        <div @click="category_subordinate(categorie)" class="d-flex flex-column align-center justify-center">
          <v-avatar size="150" tile>
          <v-img :src="categorie.icon"></v-img>
          </v-avatar>
            <span class="font mt-1 font-weight-bold" v-text="categorie.title"></span>
        </div>
      </v-col>
    </v-row>
    <div>
      <action/>
    </div>
    </v-main>
    </v-container>
  </div>
</template>
<script>
import { categories_list } from './models/categories'
import { mapState } from 'vuex'
import navbar from '@/components/navbar/appBar'
import store from '@/store'
import LoadingPage from '../../../components/tools/loadingPage.vue';
export default {
  components: {
    navbar,
    LoadingPage,
    action: () => import ('../home/components/navbar/actionBar'),
  },
     computed: {
    ...mapState({
      categories: state => state.categories.categories,
      loading: state => state.search.load_page,
    })
  },
  created () {
    store.dispatch('clear_search_result')
    localStorage.removeItem('filter_search')
      categories_list('')
      localStorage.removeItem('subordinate')
  },
  methods: {
    category_subordinate (category) {
      localStorage.setItem('category_id', JSON.stringify(category))
      this.$router.push('/subordinate')
    }
  } 
}
</script>